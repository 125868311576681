import React from 'react'
import { Layout, UserContext } from '../../components/Layout'
import { Heading } from '../../components/Heading'
import { HeadingLevel } from 'baseui/heading'
import { useStyletron } from 'baseui'
import { Grid, Cell } from 'baseui/layout-grid'
import { ourColors } from '../../components/Colors'
import { Logout } from '../../components/Logout'
import { LinkButton } from '../../components/LinkButton'
import { HorizontalLine } from '../../components/Line'
import { PaddedAttribute } from '../../components/PaddedAttribute'
import { loginRedirect } from '../../utils'

export const AccountView = () => {
  return (
    <Grid>
      <Cell span={[4, 6]}>
        <AccountHeader logoutVisible={true} />
      </Cell>
      <Cell span={[4, 6]}>
        <HorizontalLine />
      </Cell>
      <Cell span={[4, 6]}>
        <AccountViewContent />
      </Cell>
    </Grid>
  )
}

interface AccountHeaderProps {
  logoutVisible: boolean
}

export const AccountHeader = (props: AccountHeaderProps) => {
  const [css] = useStyletron()

  const logoutButtonOverrides = {
    BaseButton: {
      style: {
        fontWeight: 400,
        backgroundColor: ourColors.utechBlue,
        justifySelf: 'flex-end',
        marginTop: '0px',
        alignSelf: 'center',
        maxWidth: '100px',
        width: '50px',
        flexGrow: 1
      }
    }
  }
  return (
    <div
      className={css({
        display: 'inline-flex',
        justifyContent: 'space-between',
        width: '100%'
      })}
    >
      <HeadingLevel>
        <Heading flex={2}>Your Account</Heading>
      </HeadingLevel>
      {props.logoutVisible ? <Logout overrides={logoutButtonOverrides} /> : null}
    </div>
  )
}

export const AccountViewContent = () => {
  const { token, preferredName, name, email, unionName, role } = React.useContext(UserContext)
  const [css] = useStyletron()

  React.useEffect(() => {
    if (!token) {
      loginRedirect()
    }
  }, [token])

  return (
    <>
      <div
        className={css({
          fontFamily: 'roboto',
          fontWeight: 'normal',
          lineHeight: '24px',
          fontSize: '18px',
          marginBottom: '10px'
        })}
      >
        <PaddedAttribute title="Preferred Name" value={preferredName} />
        <PaddedAttribute title="Full Name" value={name} />
        <PaddedAttribute title="Email" value={email} />
        <PaddedAttribute title="Password" value="**********" />
        <PaddedAttribute title="Union" value={unionName} />
        <PaddedAttribute title="Role" value={role} />
      </div>
      <LinkButton
        to="/account/edit"
        className={css({
          fontWeight: 400,
          backgroundColor: ourColors.utechBlue,
          minWidth: '160px',
          width: '60%',
          maxWidth: '250px',
          display: 'inline-block'
        })}
        data-testid="changeDetails"
      >
        Change Details
      </LinkButton>
    </>
  )
}

const LayoutAccountView = () => {
  return (
    <Layout>
      <AccountView />
    </Layout>
  )
}
export default LayoutAccountView
