import { Button } from './Button'
import React from 'react'
import { UserContext } from './Layout'
import { logout } from '../api/user'
import { navigate } from 'gatsby'
import { ButtonOverrides } from 'baseui/button'

interface LogoutProps {
  overrides?: ButtonOverrides
}

export const Logout = (props: LogoutProps) => {
  const [loggingOut, setLoggingOut] = React.useState(false)
  const { setUserContext, token } = React.useContext(UserContext)
  const setUserContextToNullAndStopSpinner = () => {
    window.localStorage.removeItem('user')
    window.sessionStorage.removeItem('user')
    if (setUserContext) {
      setUserContext(null, false)
    }
    setLoggingOut(false)
    navigate('/logged-out')
  }
  const onLogout = () => {
    if (!loggingOut && token) {
      setLoggingOut(true)
      if (token) {
        logout(token, setUserContextToNullAndStopSpinner, setUserContextToNullAndStopSpinner)
      } else {
        setUserContextToNullAndStopSpinner()
      }
    }
  }
  return (
    <Button
      kind="secondary"
      data-testid={loggingOut ? 'logout-button-loading' : 'logout-button'}
      onClick={onLogout}
      isLoading={loggingOut}
      overrides={props.overrides}
    >
      Log out
    </Button>
  )
}
