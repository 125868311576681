import { Link as GatsbyLink, GatsbyLinkProps } from 'gatsby'
import React from 'react'
import { styled } from 'baseui'

import { Theme } from 'baseui/theme'

export const LinkButton = styled<{}, React.ComponentClass<GatsbyLinkProps<{}>, {}>, Theme>(
  GatsbyLink,
  ({ $theme }) => {
    const { colors } = $theme
    return {
      minWidth: '33%',
      paddingTop: '14px',
      paddingBottom: '14px',
      paddingLeft: '16px',
      paddingRight: '16px',
      maxWidth: '345px',
      borderTopLeftRadius: '5px',
      borderTopRightRadius: '5px',
      borderBottomLeftRadius: '5px',
      borderBottomRightRadius: '5px',
      fontSize: '18px',
      lineHeight: 'normal',
      fontFamily: 'Roboto, sans-serif',
      color: colors.buttonSecondaryText,
      backgroundColor: colors.buttonSecondaryFill,
      marginTop: '12px',
      marginBottom: '12px',
      textDecoration: 'none',
      textAlign: 'center'
    }
  }
)

export const ExternalLinkButton = styled<{}, 'a', Theme>('a', ({ $theme }) => {
  const { colors } = $theme
  return {
    minWidth: '33%',
    paddingTop: '14px',
    paddingBottom: '14px',
    paddingLeft: '16px',
    paddingRight: '16px',
    maxWidth: '345px',
    borderTopLeftRadius: '5px',
    borderTopRightRadius: '5px',
    borderBottomLeftRadius: '5px',
    borderBottomRightRadius: '5px',
    fontSize: '18px',
    lineHeight: 'normal',
    fontFamily: 'Roboto, sans-serif',
    color: colors.buttonSecondaryText,
    backgroundColor: colors.buttonSecondaryFill,
    marginTop: '12px',
    marginBottom: '12px',
    textDecoration: 'none',
    textAlign: 'center'
  }
})
