import constants from '../constants'
import { getData, postFormData } from './cms'
import axios from 'axios'

export type Role = 'HSR' | 'Organiser'
export interface LoginResponse {
  key?: string
  user?: {
    pk: number
    email: string
    name: string
    preferred_name: string
    role: Role
    union: string
    union_name: string
    has_created_issue: boolean
  }
}

export interface LoginErrors {
  non_field_errors?: string[]
  email?: string[]
  password?: string[]
}

export interface PasswordResetErrors {
  non_field_errors?: string[]
  new_password1?: string[]
  new_password2?: string[]
  uid?: string[]
  token?: string[]
}

export async function getUserDetails(
  token: string,
  success: (response: LoginResponse) => void
): Promise<LoginResponse> {
  const url = `${constants.cmsUrl}/user/refresh`
  const result = getData(url, { Authorization: 'Token ' + token })
  await result.then(success)
  return result
}

export async function postData(url = '', data = {}, headers = {}) {
  const response = await axios
    .post(url, data, {
      headers: {
        'Content-Type': 'application/json',
        ...headers
      },
      withCredentials: false
    })
    .then(successResponse => successResponse.data)
    .catch(error => {
      throw error.response.data
    })

  return response
}

export function logout(
  token: string,
  success: () => void,
  error: (reason: any) => void
): Promise<void | LoginResponse> {
  const url = `${constants.cmsUrl}/rest-auth/logout/`
  return postData(url, {}, { Authorization: token }).then(success, error)
}

export interface User {
  preferredName?: string
  name?: string
  email?: string
  oldPassword?: string
  newPassword?: string
  role?: Role
  phone_number?: string
  union_membership_number?: string
  union?: string | number
}

export function updateUser(
  token: string,
  data: User,
  success: (response: LoginResponse) => void,
  error: (reason: any) => void
): Promise<void | LoginResponse> {
  const url = `${constants.cmsUrl}/user/update/`
  return postData(url, { data }, { Authorization: 'Token ' + token }).then(success, error)
}

export function login(
  email: string,
  password: string,
  success: (loginResponse: LoginResponse) => void,
  error: (reasons: LoginErrors) => LoginResponse | PromiseLike<LoginResponse> | void
): Promise<void | LoginResponse> {
  const url = `${constants.cmsUrl}/rest-auth/login/`
  return postData(url, { email: email, password: password }).then(success, error)
}

export function passwordResetConfirm(
  uid: string,
  token: string,
  password: string,
  passwordRepeat: string,
  success: (loginResponse: LoginResponse) => void,
  error: (reason: PasswordResetErrors) => LoginResponse | PromiseLike<LoginResponse> | void
): Promise<void | LoginResponse> {
  const url = `${constants.cmsUrl}/confirm-reset/`
  /* eslint-disable @typescript-eslint/camelcase */
  return postData(url, {
    uid: uid,
    token: token,
    new_password1: password,
    new_password2: passwordRepeat
  }).then(success, error)

  /* eslint-enable */
}

export interface PasswordResetRequestError {
  email: string
}

export function passwordResetRequest(
  email: string,
  success: () => void,
  error: (reason: PasswordResetRequestError) => void
): Promise<void> {
  const url = `${constants.cmsUrl}/rest-auth/password/reset/`
  /* eslint-disable @typescript-eslint/camelcase */
  return postData(url, {
    email: email
  }).then(success, error)
  /* eslint-enable */
}

export function createUser(data: User) {
  const url = `${constants.cmsUrl}/user/create`
  return postFormData(null, url, {}, data)
}
