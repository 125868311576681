import React from 'react'
import { ParagraphLarge, LabelLarge } from 'baseui/typography'

interface PaddedAttributeProps {
  title: string
  value: string | JSX.Element[] | null
}
export const PaddedAttribute = (props: PaddedAttributeProps) => (
  <>
    <LabelLarge
      overrides={{
        Block: {
          style: {
            marginBottom: '5px'
          }
        }
      }}
    >
      {props.title}
    </LabelLarge>
    <ParagraphLarge
      overrides={{
        Block: {
          style: {
            marginBottom: '14px'
          }
        }
      }}
    >
      {props.value}
    </ParagraphLarge>
  </>
)
